import React from 'react';
import Helmet from 'react-helmet';
import { Container, NavLink } from 'react-bootstrap';
import styled from 'styled-components';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
    };
  }

  updateDimensions = () => {
    if (this.state.height !== window.innerHeight) {
      this.setState({ height: window.innerHeight });
    }
  };

  componentDidMount() {
    this.setState({ height: window.innerHeight });
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const Heading1 = styled.h1`
      font-size: 80px;
      font-family: Teko;
      line-height: 40px;
      color: #ff0084;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      @media (min-width: 768px) {
        margin-left: 4px;
      }
      @media (max-width: 767px) {
        margin-left: 2px;
      }
      @media (min-width: 768px) and (max-width: 1500px) {
        font-size: 40px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 20px;
      }
    `;

    const Heading3 = styled.h6`
      font-size: 24px;
      font-family: Teko;
      line-height: 40px;
      color: #ff0084;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      @media (min-width: 768px) {
        margin-left: 4px;
      }
      @media (max-width: 767px) {
        margin-left: 2px;
      }
      @media (min-width: 768px) and (max-width: 1500px) {
        font-size: 30px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 20px;
      }
    `;
    const Heading4 = styled.h6`
      font-size: 18px;
      font-family: sans-serif;
      line-height: 30px;
      color: #fff;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      @media (min-width: 768px) {
        margin-left: 4px;
      }
      @media (max-width: 767px) {
        margin-left: 2px;
      }
      @media (min-width: 768px) and (max-width: 1500px) {
        font-size: 18px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
        text-align: left;
      }
    `;

    const Main = styled.div`
      width: 100%;
      display: flex;
      padding: 20px;
      background-color: rgb(22, 26, 40);
    `;

    const Content = styled.div`
      position: relative;
      z-index: 10;
    `;
    const Link = styled.div`
      display: block;
      position: relative;
      z-index: 10;
      margin: 10px;
      font-size: 18px;
    `;

    return (
      <div>
        <Helmet>
          <title>Privacy Policy</title>
          <meta name="description" content="Page not found" />
        </Helmet>
        <Main>
          <Container>
            <Content>
              <Heading1>Privacy Policy Fun Games Studio</Heading1>
              <Heading3>Pirvacy Policy</Heading3>
              <Heading4>
                Fun Games app as a Commercial app. This SERVICE is provided by
                Fun Games and is intended for use as is. This page is used to
                inform visitors regarding our policies with the collection, use,
                and disclosure of Personal Information if anyone decided to use
                our Service. If you choose to use our Service, then you agree to
                the collection and use of information in relation to this
                policy. The Personal Information that we collect is used for
                providing and improving the Service. We will not use or share
                your information with anyone except as described in this Privacy
                Policy. The terms used in this Privacy Policy have the same
                meanings as in our Terms and Conditions, which is accessible at
                our game unless otherwise defined in this Privacy Policy.
              </Heading4>
              <Heading3>Information Collection and Use</Heading3>
              <Heading4>
                For a better experience, while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to Location. The
                information that we request will be retained by us and used as
                described in this privacy policy. The app does use third party
                services that may collect information used to identify you. Link
                to privacy policy of third party service providers used by the
                app
              </Heading4>
              <Link>
                <a
                  href="https://policies.google.com/privacy"
                  class="badge badge-success"
                >
                  Google Play Services
                </a>
              </Link>
              <Link>
                <a
                  href="https://unity.com/legal/privacy-policy"
                  class="badge badge-success"
                >
                  Unity3D
                </a>
              </Link>
              <Link>
                <a
                  href="https://gameanalytics.com/docs/s/article/Fair-Use-Policy"
                  class="badge badge-success"
                >
                  Game Analytics
                </a>
              </Link>
              <Link>
                <a
                  href="https://www.facebook.com/about/privacy/previous"
                  class="badge badge-success"
                >
                  Facebook
                </a>
              </Link>
              <Heading3>Log Data</Heading3>
              <Heading4>
                We want to inform you that whenever you use our Service, in a
                case of an error in the app we collect data and information
                (through third party products) on your phone called Log Data.
                This Log Data may include information such as your device
                Internet Protocol (“IP”) address, device name, operating system
                version, the configuration of the app when utilizing our
                Service, the time and date of your use of the Service, and other
                statistics.
              </Heading4>
              <Heading3>Cookies</Heading3>
              <Heading4>
                Cookies are files with a small amount of data that are commonly
                used as anonymous unique identifiers. These are sent to your
                browser from the websites that you visit and are stored on your
                device's internal memory. This Service does not use these
                “cookies” explicitly. However, the app may use third party code
                and libraries that use “cookies” to collect information and
                improve their services. You have the option to either accept or
                refuse these cookies and know when a cookie is being sent to
                your device. If you choose to refuse our cookies, you may not be
                able to use some portions of this Service.
              </Heading4>
            </Content>
            <Heading3>Service Providers</Heading3>
            <Heading4>
              We may employ third-party companies and individuals due to the
              following reasons:
              <ul style={{ textAlign: 'left' }}>
                <li>To facilitate our Service</li>
                <li>To provide the Service on our behalf</li>
                <li>To perform Service-related services</li>
                <li>To assist us in analyzing how our Service is used.</li>
              </ul>
              We want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </Heading4>
            <Heading3>Security</Heading3>
            <Heading4>
              We value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </Heading4>
            <Heading3>Links to Other Sites</Heading3>
            <Heading4>
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we
              strongly advise you to review the Privacy Policy of these
              websites. We have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </Heading4>
            <Heading3>Children’s Privacy</Heading3>
            <Heading4>
              These Services do not address anyone under the age of 13. We do
              not knowingly collect personally identifiable information from
              children under 13 years of age. In the case we discover that a
              child under 13 has provided us with personal information, we
              immediately delete this from our servers. If you are a parent or
              guardian and you are aware that your child has provided us with
              personal information, please contact us so that we will be able to
              do necessary actions.
            </Heading4>
            <Heading3>Changes to This Privacy Policy</Heading3>
            <Heading4>
              We may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on
              this page. This policy is effective as of 2021-03-13
            </Heading4>
            <Heading3>Contact Us</Heading3>
            <Heading4>
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us at rivalwheels@gmail.com. This privacy policy page was created at 
            </Heading4>
            <Link>
                <a
                  href="https://www.google.com/url?q=https://www.google.com/url?q%3Dhttps://privacypolicytemplate.net/%26amp;sa%3DD%26amp;source%3Deditors%26amp;ust%3D1661697139654453%26amp;usg%3DAOvVaw3oXht_h29MzlevjXORjU9o&sa=D&source=docs&ust=1661697139663043&usg=AOvVaw37PbEWv5kgJXhLeaSVdPnW"
                  class="badge badge-success"
                >
                  privacypolicytemplate.net
                </a>
              </Link>
              <Heading4>
              and modified/generated by
              </Heading4>
            
              <Link>
                <a
                  href="https://www.google.com/url?q=https://www.google.com/url?q%3Dhttps://app-privacy-policy-generator.nisrulz.com/%26amp;sa%3DD%26amp;source%3Deditors%26amp;ust%3D1661697139654757%26amp;usg%3DAOvVaw1W0MFpCp8POXaZ3T9QiBK4&sa=D&source=docs&ust=1661697139663186&usg=AOvVaw3KlRCzsJHqcs5wvOzFHkSB"
                  class="badge badge-success"
                >
                 App Privacy Policy Generator
                </a>
              </Link>
          </Container>
        </Main>
      </div>
    );
  }
}

export default PrivacyPolicy;
